import * as React from "react";
import { graphql } from "gatsby";

// Layout

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";
import { buildFluidImageData } from "@imgix/gatsby";

// Custom Components
import SecondaryNav from "../../components/SecondaryNav";

import PageBanner from "../../components/PageBanner";

// Tailwind React UI Components
import { Section } from "tailwind-react-ui";

// GraphQL
export const query = graphql`
  query PlanYourVisitPageQuery {
    entry: craftPlanYourVisitPlanYourVisitEntry {
      id
      uri
      url
      title
      subtitle
      summary
      featureImage {
        ...UploadAssetFragment
      }
      displayTitle
      metaTitle
      metaDescription
      metaKeywords
      metaImage {
        ...UploadAssetFragment
      }
      introductionRow {
        ...IntroductionRow
      }
      applicableDates
      applicableDatesNew

      infoRow {
        ...InfoRowFragment
      }
      infoRowNew {
        ...InfoRowNewFragment
      }
      seasonalHours {
        ...SeasonalHoursFragment
      }
      imageOnly {
        ...UploadAssetFragment
      }
      richText
      buttons {
        ...ButtonsFragment
      }
    }
    siteInfo: craftSiteInformationGlobalSet {
      ...SiteInfoFragment
    }
    navigation: allCraftMainNavigationRelatedEntryEntry(
      filter: { relatedEntry: { elemMatch: { sectionId: { eq: 11 } } } }
    ) {
      group(field: relatedEntry___sectionId) {
        nodes {
          relatedEntry {
            title
            uri
          }
        }
      }
    }
  }
`;

const truncateString = (string = "", maxLength = 54) =>
  string.length > maxLength ? `${string.substring(0, maxLength)}…` : string;

// {data.site.title}
const PlanYourVisit = ({ data: { entry, siteInfo, navigation } }) => {
  // secondary navigation
  const secondaryNav = navigation.group[0].nodes;

  const subtitle = entry.subtitle || siteInfo.siteTitle;
  const [siteImage] = siteInfo.siteImage;
  const [featureImage = siteImage] = entry.featureImage;
  const metaImage = featureImage || entry.metaImage || siteInfo.siteImage;
  const metaDescription = truncateString(
    (
      entry.metaDescription ||
      entry.summary ||
      siteInfo.siteDescription
    ).replace(/(<([^>]+)>)/gi, ""),
    159
  );
  const metaTitle = truncateString(
    `${entry.metaTitle || entry.displayTitle || entry.title}`,
    53
  );
  const metaKeywords = entry.metaKeywords || siteInfo.siteKeywords || null;

  const metaImageFormatted = buildFluidImageData(`${metaImage.url}`, {
    ar: 1, // required
    auto: ["format", "compress"],
    sizes: `300px, 300px`, // optional, but highly recommended
    width: 300,
    height: 300,
  });

  return (
    <Layout location={entry.url} title={metaTitle}>
      <Seo
        title={metaTitle}
        description={metaDescription}
        keywords={metaKeywords}
        image={metaImageFormatted.src}
        url={entry.url}
        pathname={`/${entry.uri}`}
      />
      <h1 style={{ fontSize: 0, color: "transparent" }} className="sr-only">
        {metaTitle}
      </h1>

      <Section p="0" className="p-0 relative">
        <PageBanner
          title={entry.title}
          subtitle={subtitle}
          image={featureImage}
        />
      </Section>

      <SecondaryNav items={secondaryNav} />
    </Layout>
  );
};

export default PlanYourVisit;
